import React, { Component, Fragment } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
//import { GoogleLogin } from '@react-oauth/google';
import { GoogleLogin } from 'react-google-login';
import { FcGoogle } from 'react-icons/fc';

import { connect } from "react-redux";
import { login } from "../actions/authActions";
import config from '../config.json';
import { withRouter, Redirect } from "react-router-dom";
import { gapi } from "gapi-script";
import Slider from "react-slick";

import bg1 from "../assets/utils/images/originals/PHOTO-1-SSPORTAL.jpg";
import bg2 from "../assets/utils/images/originals/SS-PORTAL-2.jpg";
import logo from "../assets/utils/images/infotec_logo.png";

import { Col, Row, Button, Form, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const mensajeLogin='';

class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modal: false,
  };

  window.gapi.load("auth2", () => {
    window.gapi.auth2.init({
      client_id: config.GOOGLE_CLIENT_ID,
      scope: "email",
    })
  })

    this.toggle = this.toggle.bind(this);
    }

    responseGoogle = (response) => {
        console.log("response");
        //console.log(response);
    }


  onFailure = (error) => {
    alert(error);
  };

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
}

googleResponse = (response) => {
  if (!response.tokenId) {
    console.error("Unable to get tokenId from Google", response)
    return;
  }

  const bod = { 'tokenId': response.tokenId }
  const values = JSON.stringify(bod);

  const options = {
    method: 'POST',
    headers: { 'Access-Control-Allow-Origin': true,'Content-Type': 'application/json' },
    body: values,
    /*mode: 'cors',
    cache: 'default'*/
  };

  fetch(config.GOOGLE_AUTH_CALLBACK_URL, options)
    .then(r => {
      r.json().then(user => {
        const token = user.token;
        if(user.autenticado ===1)          
        {
          this.props.login(user);
          var data = {id:3,url:""};
          this.props.history.push({
              pathname: '/',
              query:data,
            }) 
        }
          else
          {
          this.renderModal(user.error);
          this.toggle();
          }
      });
    })
};


  renderModal(mensaje) {
    this.mensajeLogin = mensaje;
 }

  render() {
    let settings = {
      dots: true,
      infinite: true,
      speed: 500,
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      initialSlide: 0,
      autoplay: true,
      adaptiveHeight: true,
    };
    
    let content = !!this.props.auth.isAuthenticated ?
      (
        <div>
          <Redirect to={{
            pathname: '/'
          }} />
        </div>
      ) :
      (
        <Fragment>
        <div className="h-100">
          <Row className="h-100 g-0">
          <Col lg="4" md="12" sm="12" className="h-100 d-flex bg-white align-items-center">
              <Col xl="9" lg="9" md="7" sm="8" className="app-login-box mx-auto">
                <div >
                <div style={{ width: '270px', margin:'auto'}}>
                  <img src={logo} alt="react logo" style={{ width: '270px', }}/>
                </div>                
                <div style={{ width: '270px', margin:'auto', paddingTop:'60px', textAlign:'center'}}>

                
                {/*}<GoogleOAuthProvider 
                    clientId={config.GOOGLE_CLIENT_ID}
      >{*/}
                  <GoogleLogin
                      //color="primary"
                      scope= 'profile email'
                      clientId={config.GOOGLE_CLIENT_ID}
                      buttonText="Sign in with Google"
                      onSuccess={this.googleResponse}
                      onFailure={this.googleResponse}
                      theme= 'dark'
                      width= '240'
                      height= '50'
                      longtitle= 'true'
                      cookiePolicy={"single_host_origin"}
                      //scope="profile"            
                    />
                {/*}</GoogleOAuthProvider>{*/}
                
                </div>
                </div>
              </Col>
            </Col>

            <Col lg="8" className="d-none d-lg-block">
              <div className="slider-light">
                <Slider {...settings}>
                  <div className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                    <div className="slide-img-bg"
                      style={{
                        backgroundImage: "url(" + bg1 + ")",
                      }}/>
                  </div>
                  <div className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                    <div className="slide-img-bg opacity-6"
                      style={{
                        backgroundImage: "url(" + bg2 + ")",
                      }}/>
                  </div>
                </Slider>
              </div>
            </Col>

          </Row>
        </div>
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                    <ModalHeader toggle={this.toggle}>Login</ModalHeader>
                    <ModalBody>
                      <p>{ this.mensajeLogin}</p>
                    </ModalBody>
                    <ModalFooter>
                    <Button color="primary" onClick={this.toggle}>
                    Aceptar
                    </Button>
                    </ModalFooter>
                </Modal> 
      </Fragment>
      );

    return (
      <div>
          {content}
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (token) => {
      dispatch(login(token));
    }
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
